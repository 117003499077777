<template>
  <section id="slider">
    <div class="w-100 position-relative">
      <div class="feat-wrap position-relative w-100">
        <div class="feat-caro">
          <div class="feat-item">
            <div class="feat-img position-absolute slide1"></div>
            <div class="container">
              <div class="feat-cap">
                <h2 class="mb-0">
                  Encuentra profesionales de <br />
                  confianza con nuestra web
                  <strong class="d-block thm-clr">- Tkonstruye.</strong>
                </h2>
                <p class="mb-0">
                  Utiliza nuestro sistema de busqueda y gestión para ubicar al
                  profesional que se adapte a tus necesidades.
                </p>
                <div class="feat-cap-innr">
                  <a
                    class="thm-btn thm-bg"
                    href="about.html"
                    title=""
                    data-toggle="modal"
                    data-target="#wizardModal"
                    >Pide Presupuesto<i
                      class="flaticon-arrow-pointing-to-right"
                    ></i
                  ></a>
                  <a
                    class="video-btn"
                    href="https://www.youtube.com/embed/6gUOzbhtVd4"
                    data-fancybox
                    title=""
                    ><span class="spinner"><i class="flaticon-play"></i></span
                    >Ver Vídeo<br />
                    Tutorial</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="feat-item">
            <div class="feat-img position-absolute slide2"></div>
            <div class="container">
              <div class="feat-cap">
                <h2 class="mb-0">
                  Encuentra profesionales de <br />
                  confianza con nuestra web
                  <strong class="d-block thm-clr">- Tkonstruye.</strong>
                </h2>
                <p class="mb-0">
                  Utiliza nuestro sistema de busqueda y gestión para ubicar al
                  profesional que se adapte a tus necesidades.
                </p>
                <div class="feat-cap-innr">
                  <a
                    class="thm-btn thm-bg"
                    href="about.html"
                    title=""
                    data-toggle="modal"
                    data-target="#wizardModal"
                    >Pide Presupuesto<i
                      class="flaticon-arrow-pointing-to-right"
                    ></i
                  ></a>
                  <a
                    class="video-btn"
                    href="https://www.youtube.com/embed/6gUOzbhtVd4"
                    data-fancybox
                    title=""
                    ><span class="spinner"><i class="flaticon-play"></i></span
                    >Ver Vídeo<br />
                    Tutorial</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="feat-item">
            <div class="feat-img position-absolute slide3"></div>
            <div class="container">
              <div class="feat-cap">
                <h2 class="mb-0">
                  Encuentra profesionales de <br />
                  confianza con nuestra web
                  <strong class="d-block thm-clr">- Tkonstruye.</strong>
                </h2>
                <p class="mb-0">
                  Utiliza nuestro sistema de busqueda y gestión para ubicar al
                  profesional que se adapte a tus necesidades.
                </p>
                <div class="feat-cap-innr">
                  <a
                    class="thm-btn thm-bg"
                    href="about.html"
                    title=""
                    data-toggle="modal"
                    data-target="#wizardModal"
                    >Pide Presupuesto<i
                      class="flaticon-arrow-pointing-to-right"
                    ></i
                  ></a>
                  <a
                    class="video-btn"
                    href="https://www.youtube.com/embed/6gUOzbhtVd4"
                    data-fancybox
                    title=""
                    ><span class="spinner"><i class="flaticon-play"></i></span
                    >Ver Vídeo<br />
                    Tutorial</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Featured Area Wrap -->
    </div>
  </section>
</template>

<script>

export default {
  name: "Slider"
};
</script>

<style scoped>
.slide1 {
  background-image: url("../../../assets/tkonstruye/slider/1.jpg");
}
.slide2 {
  background-image: url("../../../assets/tkonstruye/slider/2.jpg");
}
.slide3 {
  background-image: url("../../../assets/tkonstruye/slider/3.jpg");
}
</style>