<template>
  <div class="copyright w-100 text-center bg-color6 position-relative">
    <div class="container">
      <p class="mb-0">
        Copyright by <a href="index.html" title="">@Blubuild</a>. All Rights
        Reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright"
};
</script>
