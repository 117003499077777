<template>
  <div id="dashboardIndex" class="post-detail w-100">
    <h3 class="mb-0"><i class="fas fa-tachometer-alt"></i> Dashboard</h3>
    <img
      class="img-fluid w-100 pt-50"
      src="../../assets/blubuild/images/resources/blog-detail-img.jpg"
      alt="Blog Detail Image"
    />
    <h2 class="mb-0">Technical Support ( Computer Repair & Help Desk )</h2>
    <p class="mb-0">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's text ever since the 1500s, when an
      unknown printer took a galley of type and scrambled it to make a type
      specimen vived not only five centuries, but also the leap into electronic
      typesetting, remaining essentially unchanged. It was pop
    </p>
    <p class="mb-0">
      1960s with the release of Letraset sheets containing Lorem Ipsum passages,
      and more recently with desktop publishin Aldus PageMaker including
      versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing
      and typesetting industry. Lorem Ipsum has been the industry's text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen. <br />
      vived not only five centuries, but also the leap into electronic
      typesetting. <br />
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's
      <br />
      text ever since the 1500s, when an unknown printer took a galley of type
      and scrambled it to make a type specimen
    </p>
    <div class="detail-gal w-100">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6">
          <a
            href="assets/images/resources/blog-detail-gallery-img1.jpg"
            data-fancybox="gallery"
            title=""
            ><img
              class="img-fluid"
              src="../../assets/blubuild/images/resources/blog-detail-gallery-img1.jpg"
              alt="Blog Detail Gallery Image 1"
          /></a>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6">
          <a
            href="assets/images/resources/blog-detail-gallery-img2.jpg"
            data-fancybox="gallery"
            title=""
            ><img
              class="img-fluid"
              src="../../assets/blubuild/images/resources/blog-detail-gallery-img2.jpg"
              alt="Blog Detail Gallery Image 2"
          /></a>
        </div>
      </div>
    </div>
    <p class="mb-0">
      Donec scelerisque dolor id nunc dictum, interdum gravida mauris rhoncus.
      Aliquam at ultrices nunc. In sem leo, fermentum at lorem in, porta finibus
      mauris. Aliquam consectetur, ex in gravida porttitor,
    </p>
    <p class="mb-0">
      Donec scelerisque dolor id nunc dictum, interdum gravida mauris rhoncus.
      Aliquam at ultrices nunc. In sem leo, fermentum at lorem in, porta finibus
      mauris. Aliquam consectetur, ex in gravida porttitor,
    </p>
    <blockquote>
      <p class="mb-0">
        There are many variations of passages of Lorem Ipsum available, but the
        majority have suffered alteration in some form.
      </p>
    </blockquote>
    <p class="mb-0">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's text ever since the 1500s, when an
      unknown printer took a galley of type and scrambled it to make a type
      specimen vived not only five centuries, but also the leap into electronic
      typesetting, remaining essentially unchanged. It was pop
    </p>
    <p class="mb-0">
      1960s with the release of Letraset sheets containing Lorem Ipsum passages,
      and more recently with desktop publishin Aldus PageMaker including
      versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing
      and typesetting industry. Lorem Ipsum has been the industry's text ever
      since the 1500s, when an unknown printer took a galley of type and
      scrambled it to make a type specimen.
    </p>
    <div class="detail-share w-100">
      <span>Share:</span>
      <a
        class="facebook-clr"
        href="javascript:void(0);"
        title="Facebook"
        target="_blank"
        ><i class="fab fa-facebook-f"></i
      ></a>
      <a
        class="google-clr"
        href="javascript:void(0);"
        title="Google Plus"
        target="_blank"
        ><i class="fab fa-google-plus-g"></i
      ></a>
      <a
        class="twitter-clr"
        href="javascript:void(0);"
        title="Twitter"
        target="_blank"
        ><i class="fab fa-twitter"></i
      ></a>
      <a
        class="skype-clr"
        href="javascript:void(0);"
        title="Skype"
        target="_blank"
        ><i class="fab fa-skype"></i
      ></a>
      <a class="rss-clr" href="javascript:void(0);" title="Rss" target="_blank"
        ><i class="fas fa-rss"></i
      ></a>
    </div>
    <div class="comments w-100">
      <h3 class="mb-0">Comments (2)</h3>
      <ul class="comments-thread mb-0 list-unstyled">
        <li>
          <div class="comment w-100">
            <div class="comment-img">
              <span class="rounded-circle"
                ><img
                  class="img-fluid rounded-circle"
                  src="../../assets/blubuild/images/resources/comment-img1.png"
                  alt="Comment Image 1"
              /></span>
            </div>
            <div class="comment-detail">
              <h4 class="mb-0">Kosmi Kotalia</h4>
              <p class="mb-0">
                There are many variations of passages of Lorem Ipsum available,
                but the majority some form, by injected humour, or randomised
                words which.
              </p>
              <a
                class="comment-reply-link d-inline-block"
                href="javascript:void(0);"
                title=""
                ><i class="fas fa-reply-all"></i>Reply</a
              >
              <span class="d-inline-block"
                ><i class="far fa-clock"></i>39 minutes ago</span
              >
            </div>
          </div>
          <ul class="children mb-0 list-unstyled">
            <li>
              <div class="comment w-100">
                <div class="comment-img">
                  <span class="rounded-circle"
                    ><img
                      class="img-fluid rounded-circle"
                      src="../../assets/blubuild/images/resources/comment-img2.png"
                      alt="Comment Image 2"
                  /></span>
                </div>
                <div class="comment-detail">
                  <h4 class="mb-0">Envato Market</h4>
                  <p class="mb-0">
                    There are many variations of passages of Lorem Ipsum
                    available, but some form, by injected humour, or randomised
                    words.
                  </p>
                  <a
                    class="comment-reply-link d-inline-block"
                    href="javascript:void(0);"
                    title=""
                    ><i class="fas fa-reply-all"></i>Reply</a
                  >
                  <span class="d-inline-block"
                    ><i class="far fa-clock"></i>39 minutes ago</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- Comments -->
    <div class="reply-form w-100">
      <h3 class="mb-0">Leave Reply</h3>
      <form class="w-100">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-lg-6">
            <div class="field-wrap w-100">
              <label>Nick name:</label>
              <input type="text" />
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6">
            <div class="field-wrap w-100">
              <label>Email Address:</label>
              <input type="email" />
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-lg-12">
            <div class="field-wrap w-100">
              <label>Write a message:</label>
              <textarea></textarea>
            </div>
            <button class="thm-btn thm-bg" type="submit">
              Learn More<i class="flaticon-arrow-pointing-to-right"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>