<template>
  <div class="home">
    <main>
      <Header />
      <!-- Header -->
      <StickyMenu />
      <!-- Sticky Menu -->
      <ResponsiveHeader />
      <!-- Responsive Header -->
      <Slider />
      <section>
        <div class="w-100 pt-100 pb-100 position-relative">
          <div class="container">
            <div class="about-wrap w-100">
              <div class="row justify-content-center">
                <div class="col-md-12 col-sm-12 col-lg-10">
                  <div class="about-content-wrap w-100">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-lg-4">
                        <div class="sec-title title-with-shape w-100">
                          <div class="sec-title-inner d-inline-block">
                            <span class="thm-clr d-block"
                              >WELCOME TO BLUBUILD</span
                            >
                            <h3 class="mb-0">
                              About Us <br />
                              Our Company.
                            </h3>
                          </div>
                        </div>
                        <!-- Sec Title -->
                      </div>
                      <div class="col-md-12 col-sm-12 col-lg-8">
                        <div class="about-desc w-100">
                          <h2 class="mb-0">
                            We craft beautifully useful marketing and digital
                            products that grow
                            <strong class="thm-clr d-block"
                              >- Our Blubuild.</strong
                            >
                          </h2>
                          <p class="mb-0">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim admini veniam, quis
                            nostru.
                          </p>
                        </div>
                        <div class="serv-wrap w-100">
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-lg-6">
                              <div class="serv-box w-100">
                                <i class="thm-clr flaticon-car-parts"></i>
                                <div class="serv-box-inner">
                                  <h3 class="mb-0">
                                    <a href="services-detail.html" title=""
                                      >Automotive <br />
                                      Manufacturing</a
                                    >
                                  </h3>
                                  <p class="mb-0">
                                    Eiusmod tempor incididunt ut labore et
                                    dolore magna.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-6">
                              <div class="serv-box w-100">
                                <i class="thm-clr flaticon-helmet"></i>
                                <div class="serv-box-inner">
                                  <h3 class="mb-0">
                                    <a href="services-detail.html" title=""
                                      >Owner's <br />
                                      Representation</a
                                    >
                                  </h3>
                                  <p class="mb-0">
                                    Eiusmod tempor incididunt ut labore et
                                    dolore magna.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Services Wrap -->
                      </div>
                    </div>
                  </div>
                  <div class="about-image position-relative w-100">
                    <span>18<sup>+</sup><i class="d-block">Experience</i></span>
                    <img
                      class="img-fluid w-100"
                      src="../assets/blubuild/images/resources/about-image1.jpg"
                      alt="About Image 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- About Wrap -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-100 blue-layer opc7 position-relative">
          <div
            class="fixed-bg"
            style="
              background-image: url(../assets/blubuild/images/parallax1.jpg);
            "
          ></div>
          <div class="particles-js" id="prtcl"></div>
          <div class="container">
            <div class="serv-wrap text-center w-100">
              <div class="row res-caro2">
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div
                    class="serv-box2 position-relative overflow-hidden w-100"
                    style="
                      background-image: url(../assets/blubuild/images/resources/serv-bg1.jpg);
                    "
                  >
                    <i class="thm-clr flaticon-wheel-saw"></i>
                    <div class="serv-box-inner">
                      <h3 class="mb-0">
                        <a href="services-detail.html" title=""
                          >Construction <br />
                          And Engineering</a
                        >
                      </h3>
                      <p class="mb-0">
                        Nunc laoreet, mi sed fermentum fringilla, eros metus.
                      </p>
                      <span class="d-block"
                        ><i class="thm-clr">1-</i> Builders merchant sales</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div
                    class="serv-box2 position-relative overflow-hidden w-100"
                    style="
                      background-image: url(../assets/blubuild/images/resources/serv-bg2.jpg);
                    "
                  >
                    <i class="thm-clr flaticon-helmet"></i>
                    <div class="serv-box-inner">
                      <h3 class="mb-0">
                        <a href="services-detail.html" title=""
                          >Industrial Equipments</a
                        >
                      </h3>
                      <p class="mb-0">
                        Nunc laoreet, mi sed fermentum fringilla, eros metus.
                      </p>
                      <span class="d-block"
                        ><i class="thm-clr">2-</i> Builders merchant sales</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div
                    class="serv-box2 position-relative overflow-hidden w-100"
                    style="
                      background-image: url(../assets/blubuild/images/resources/serv-bg3.jpg);
                    "
                  >
                    <i class="thm-clr flaticon-carpenter"></i>
                    <div class="serv-box-inner">
                      <h3 class="mb-0">
                        <a href="services-detail.html" title=""
                          >Owner's <br />
                          Representation</a
                        >
                      </h3>
                      <p class="mb-0">
                        Nunc laoreet, mi sed fermentum fringilla, eros metus.
                      </p>
                      <span class="d-block"
                        ><i class="thm-clr">3-</i> Builders merchant sales</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div
                    class="serv-box2 position-relative overflow-hidden w-100"
                    style="
                      background-image: url(../assets/blubuild/images/resources/serv-bg4.jpg);
                    "
                  >
                    <i class="thm-clr flaticon-jigsaw"></i>
                    <div class="serv-box-inner">
                      <h3 class="mb-0">
                        <a href="services-detail.html" title=""
                          >Fuel Gas <br />
                          Productions</a
                        >
                      </h3>
                      <p class="mb-0">
                        Nunc laoreet, mi sed fermentum fringilla, eros metus.
                      </p>
                      <span class="d-block"
                        ><i class="thm-clr">4-</i> Builders merchant sales</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Services Wrap -->
            <div
              class="facts-wrap thm-layer opc1 text-center position-relative overflow-hidden brd-rd5 w-100"
            >
              <div
                class="parallax-bg zoom-anim back-blend-multiply patern-bg thm-bg"
                style="
                  background-image: url(../assets/blubuild/images/pattern-bg1.png);
                "
              ></div>
              <div class="row">
                <div class="col-md-3 col-sm-6 col-lg-3">
                  <div class="fact-box w-100">
                    <h2 class="mb-0">
                      <span class="counter">18</span><sup>+</sup>
                    </h2>
                    <h4 class="mb-0">Experience</h4>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-lg-3">
                  <div class="fact-box w-100">
                    <h2 class="mb-0">
                      <span class="counter">502</span><sup>+</sup>
                    </h2>
                    <h4 class="mb-0">Complete Projects</h4>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-lg-3">
                  <div class="fact-box w-100">
                    <h2 class="mb-0">
                      <span class="counter">120</span><sup>+</sup>
                    </h2>
                    <h4 class="mb-0">Works Employed</h4>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-lg-3">
                  <div class="fact-box w-100">
                    <h2 class="mb-0">
                      <span class="counter">12</span><sup>+</sup>
                    </h2>
                    <h4 class="mb-0">Running Projects</h4>
                  </div>
                </div>
              </div>
            </div>
            <!-- Facts Wrap -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-210 pb-100 position-relative">
          <div class="container">
            <div class="sec-title w-100">
              <div class="sec-title-inner d-inline-block">
                <span class="d-block thm-clr">OUR WORKS</span>
                <h3 class="mb-0">Our Latest Projects</h3>
              </div>
            </div>
            <div class="proj-wrap w-100">
              <div class="row justify-content-center">
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-1.jpg"
                          alt="Project Image 1"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-helmet"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Construction & <br />
                          Engineering</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-2.jpg"
                          alt="Project Image 2"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-electric-tower"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Industry <br />
                          power grid</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-3.jpg"
                          alt="Project Image 3"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-file"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Chemical <br />
                          Research Center</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-4.jpg"
                          alt="Project Image 4"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-engineer-2"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Chemical <br />
                          Research Center</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-5.jpg"
                          alt="Project Image 5"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-worker"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Industrial <br />
                          Construction</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-6.jpg"
                          alt="Project Image 6"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-lighthouse"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Ship Building <br />
                          Industry</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-7.jpg"
                          alt="Project Image 7"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-worker-1"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Chemical <br />
                          Research Center</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-3">
                  <div class="proj-box position-relative w-100">
                    <div class="proj-thumb overflow-hidden w-100">
                      <a href="project-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/proj-img1-8.jpg"
                          alt="Project Image 8"
                      /></a>
                    </div>
                    <div class="proj-info position-absolute">
                      <i class="flaticon-construction"></i>
                      <h3 class="mb-0">
                        <a href="project-detail.html" title=""
                          >Chemical <br />
                          Research Center</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Projects Wrap -->
            <div class="view-all mt-20 w-100 text-center">
              <a class="thm-btn thm-bg" href="projects.html" title=""
                >View All Portfolio<i
                  class="flaticon-arrow-pointing-to-right"
                ></i
              ></a>
            </div>
            <!-- View All -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-100 pb-100 blue-layer opc8 position-relative">
          <div
            class="fixed-bg"
            style="
              background-image: url(../assets/blubuild/images/parallax2.jpg);
            "
          ></div>
          <div class="particles-js" id="prtcl2"></div>
          <div class="container">
            <div class="getin-touch-wrap w-100">
              <div class="row align-items-center">
                <div class="col-md-7 col-sm-12 col-lg-7">
                  <div class="getin-touch-title with-shap w-100">
                    <div
                      class="getin-touch-blob thm-bg overflow-hidden position-absolute"
                    ></div>
                    <span class="d-block">Ready to get started?</span>
                    <h2 class="mb-0">Get in touch, or create an account.</h2>
                    <p class="mb-0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
                <div class="col-md-5 col-sm-12 col-lg-5">
                  <div class="getin-touch-btn position-relative text-right">
                    <a class="thm-btn thm-bg" href="contact.html" title=""
                      >Subscribe Now<i
                        class="flaticon-arrow-pointing-to-right"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-130 pb-100 position-relative">
          <div class="container">
            <div class="sec-title2 w-100">
              <div class="sec-title-inner2 d-inline-block">
                <h2 class="mb-0">
                  Hot Deal Ship Buildup
                  <i
                    >15% <br />
                    Off</i
                  >
                </h2>
                <p class="mb-0">
                  We Fight for justice, we are always ready to best solution for
                  your problem.
                </p>
              </div>
            </div>
            <div class="solutions-wrap w-100">
              <div class="row align-items-center">
                <div class="col-md-6 col-sm-12 col-lg-6">
                  <img
                    class="img-fluid w-100"
                    src="../assets/blubuild/images/resources/solutions-img.jpg"
                    alt="Solutions Image"
                  />
                </div>
                <div class="col-md-6 col-sm-12 col-lg-6">
                  <div class="solutions-content-wrap w-100">
                    <h2 class="mb-0">TECHNICAL SOLUTIONS</h2>
                    <ul class="solutions-list mb-0 list-unstyled w-100">
                      <li>
                        <i class="">+</i>
                        <h4 class="mb-0">Expart Experience Worker.</h4>
                        <p class="mb-0">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration.
                        </p>
                      </li>
                      <li>
                        <i class="">+</i>
                        <h4 class="mb-0">20-25 Years Warranty.</h4>
                        <p class="mb-0">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration.
                        </p>
                      </li>
                      <li>
                        <i class="">+</i>
                        <h4 class="mb-0">Free 15% Buildup Construction.</h4>
                        <p class="mb-0">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- Solutions Wrap -->
          </div>
        </div>
      </section>
      <section>
        <div
          class="w-100 dark-layer2 pt-100 pb-100 opc1 overflow-hidden position-relative"
        >
          <div
            class="fixed-bg zoom-anim back-blend-screen h-tst patern-bg dark-bg"
            style="
              background-image: url(../assets/blubuild/images/pattern-bg2.jpg);
            "
          ></div>
          <div class="particles-js" id="prtcl3"></div>
          <div class="container">
            <div class="testi-wrap position-relative w-100">
              <h2 class="mb-0">WHAT CLIENTS SAYS?</h2>
              <div class="testi-caro">
                <div class="testi-box-wrap">
                  <div class="testi-box">
                    <div class="testi-img">
                      <img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/testi-img1-1.png"
                        alt="Testimonial Image 1"
                      />
                    </div>
                    <div class="testi-info">
                      <h3 class="mb-0">“Jonspond Mendela”</h3>
                      <p class="mb-0">
                        Donec scelerisque dolor id nunc dictum, nterdum mauris
                        rhoncus. Aliquam at ultrices nunc. In sem fermentum at
                        lorem in, porta mauris.
                      </p>
                      <span class="d-inline-block text-color3"
                        ><i class="fas fa-star"></i><i class="fas fa-star"></i
                        ><i class="fas fa-star"></i><i class="far fa-star"></i
                        ><i class="far fa-star"></i
                        ><span class="thm-clr">(07 Review)</span></span
                      >
                    </div>
                  </div>
                </div>
                <div class="testi-box-wrap">
                  <div class="testi-box">
                    <div class="testi-img">
                      <img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/testi-img1-2.png"
                        alt="Testimonial Image 2"
                      />
                    </div>
                    <div class="testi-info">
                      <h3 class="mb-0">“Baris Jonson”</h3>
                      <p class="mb-0">
                        Donec scelerisque dolor id nunc dictum, nterdum mauris
                        rhoncus. Aliquam at ultrices nunc. In sem fermentum at
                        lorem in, porta mauris.
                      </p>
                      <span class="d-inline-block text-color3"
                        ><i class="fas fa-star"></i><i class="fas fa-star"></i
                        ><i class="fas fa-star"></i><i class="fas fa-star"></i
                        ><i class="far fa-star"></i
                        ><span class="thm-clr">(07 Review)</span></span
                      >
                    </div>
                  </div>
                </div>
                <div class="testi-box-wrap">
                  <div class="testi-box">
                    <div class="testi-img">
                      <img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/testi-img1-3.png"
                        alt="Testimonial Image 3"
                      />
                    </div>
                    <div class="testi-info">
                      <h3 class="mb-0">“Jonson Baris”</h3>
                      <p class="mb-0">
                        Donec scelerisque dolor id nunc dictum, nterdum mauris
                        rhoncus. Aliquam at ultrices nunc. In sem fermentum at
                        lorem in, porta mauris.
                      </p>
                      <span class="d-inline-block text-color3"
                        ><i class="fas fa-star"></i><i class="fas fa-star"></i
                        ><i class="far fa-star"></i><i class="far fa-star"></i
                        ><i class="far fa-star"></i
                        ><span class="thm-clr">(07 Review)</span></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tesyimonials Wrap -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-100 pb-100 position-relative">
          <div class="container">
            <div class="sec-title w-100">
              <div class="sec-title-inner d-inline-block">
                <span class="d-block thm-clr">OUR FAQ</span>
                <h3 class="mb-0">Freequently Ask Questions</h3>
              </div>
            </div>
            <div class="faq-wrap w-100">
              <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6 order-md-1">
                  <img
                    class="img-fluid"
                    src="../assets/blubuild/images/resources/faq-mockup.png"
                    alt="Faq Mockup"
                  />
                </div>
                <div class="col-md-6 col-sm-12 col-lg-6">
                  <div class="toggle w-100" id="toggle">
                    <div class="toggle-item w-100">
                      <h4 class="mb-0">
                        <span>01.</span>How can i buy this headphone?
                      </h4>
                      <div class="toggle-content w-100">
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                    <div class="toggle-item w-100">
                      <h4 class="mb-0">
                        <span>02.</span>This is Especially for listening to
                        music?
                      </h4>
                      <div class="toggle-content w-100">
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                    <div class="toggle-item w-100">
                      <h4 class="mb-0">
                        <span>03.</span>How can i ordered it?
                      </h4>
                      <div class="toggle-content w-100">
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                    <div class="toggle-item w-100">
                      <h4 class="mb-0">
                        <span>04.</span>How can work this headphone?
                      </h4>
                      <div class="toggle-content w-100">
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                    <div class="toggle-item w-100">
                      <h4 class="mb-0">
                        <span>05.</span>This is Especially for listening to
                        music?
                      </h4>
                      <div class="toggle-content w-100">
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Faq Wrap -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-155 pb-155 blue-layer opc85 position-relative">
          <div
            class="fixed-bg"
            style="
              background-image: url(../assets/blubuild/images/parallax3.jpg);
            "
          ></div>
          <div class="particles-js" id="prtcl4"></div>
          <div class="container">
            <div class="banner-wrap position-relative text-center w-100">
              <div class="banner-inner d-inline-block">
                <h2 class="mb-0">
                  Bridging gap between Business and Technology
                </h2>
                <p class="mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since.
                </p>
                <a class="thm-btn thm-bg" href="about.html" title=""
                  >Learn More<i class="flaticon-arrow-pointing-to-right"></i
                ></a>
              </div>
            </div>
            <!-- Banner Wrap -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-100 pb-100 position-relative">
          <div class="container">
            <div class="sec-title w-100">
              <div class="sec-title-inner d-inline-block">
                <span class="d-block thm-clr">OUR BLOG</span>
                <h3 class="mb-0">Our Latest News</h3>
              </div>
            </div>
            <div class="blog-wrap w-100">
              <div class="row post-caro">
                <div class="col-md-6 col-sm-6 col-lg-4">
                  <div class="post-box w-100 text-center">
                    <div class="post-img overflow-hidden w-100">
                      <a href="blog-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/post-img1-1.jpg"
                          alt="Post Image 1"
                      /></a>
                    </div>
                    <div class="post-info w-100">
                      <h3 class="mb-0">
                        <a href="blog-detail.html" title=""
                          >Models & OEM Solutions | Simul Corporation.</a
                        >
                      </h3>
                      <p class="mb-0">
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                      <div class="post-info-bottom d-flex flex-wrap w-100">
                        <span class="d-inline-block"
                          ><i class="far fa-user"></i
                          ><a href="javascript:void(0);" title=""
                            >Jibon Hasan</a
                          ></span
                        >
                        <ul class="post-meta mb-0 list-unstyled d-inline-flex">
                          <li>
                            <i class="far fa-calendar-alt"></i>July 03, 2020
                          </li>
                          <li><i class="fas fa-comment-dots"></i>02</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-4">
                  <div class="post-box w-100 text-center">
                    <div class="post-img overflow-hidden w-100">
                      <a href="blog-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/post-img1-2.jpg"
                          alt="Post Image 2"
                      /></a>
                    </div>
                    <div class="post-info w-100">
                      <h3 class="mb-0">
                        <a href="blog-detail.html" title=""
                          >Models & OEM Solutions | Simul Corporation.</a
                        >
                      </h3>
                      <p class="mb-0">
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                      <div class="post-info-bottom d-flex flex-wrap w-100">
                        <span class="d-inline-block"
                          ><i class="far fa-user"></i
                          ><a href="javascript:void(0);" title=""
                            >Jibon Hasan</a
                          ></span
                        >
                        <ul class="post-meta mb-0 list-unstyled d-inline-flex">
                          <li>
                            <i class="far fa-calendar-alt"></i>July 03, 2020
                          </li>
                          <li><i class="fas fa-comment-dots"></i>22</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-4">
                  <div class="post-box w-100 text-center">
                    <div class="post-img overflow-hidden w-100">
                      <a href="blog-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/post-img1-3.jpg"
                          alt="Post Image 3"
                      /></a>
                    </div>
                    <div class="post-info w-100">
                      <h3 class="mb-0">
                        <a href="blog-detail.html" title=""
                          >Models & OEM Solutions | Simul Corporation.</a
                        >
                      </h3>
                      <p class="mb-0">
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                      <div class="post-info-bottom d-flex flex-wrap w-100">
                        <span class="d-inline-block"
                          ><i class="far fa-user"></i
                          ><a href="javascript:void(0);" title=""
                            >Jibon Hasan</a
                          ></span
                        >
                        <ul class="post-meta mb-0 list-unstyled d-inline-flex">
                          <li>
                            <i class="far fa-calendar-alt"></i>July 03, 2020
                          </li>
                          <li><i class="fas fa-comment-dots"></i>12</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-4">
                  <div class="post-box w-100 text-center">
                    <div class="post-img overflow-hidden w-100">
                      <a href="blog-detail.html" title=""
                        ><img
                          class="img-fluid w-100"
                          src="../assets/blubuild/images/resources/post-img1-4.jpg"
                          alt="Post Image 4"
                      /></a>
                    </div>
                    <div class="post-info w-100">
                      <h3 class="mb-0">
                        <a href="blog-detail.html" title=""
                          >Models & OEM Solutions | Simul Corporation.</a
                        >
                      </h3>
                      <p class="mb-0">
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p>
                      <div class="post-info-bottom d-flex flex-wrap w-100">
                        <span class="d-inline-block"
                          ><i class="far fa-user"></i
                          ><a href="javascript:void(0);" title=""
                            >Jibon Hasan</a
                          ></span
                        >
                        <ul class="post-meta mb-0 list-unstyled d-inline-flex">
                          <li>
                            <i class="far fa-calendar-alt"></i>July 03, 2020
                          </li>
                          <li><i class="fas fa-comment-dots"></i>12</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Blog Wrap -->
            <div class="view-all w-100 text-center">
              <a class="thm-btn thm-bg" href="blog.html" title=""
                >View All News<i class="flaticon-arrow-pointing-to-right"></i
              ></a>
            </div>
            <!-- View All -->
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pb-50 position-relative">
          <div class="container">
            <div class="clients-wrap w-100">
              <div class="row">
                <div class="col-md-3 col-sm-4 col-lg-2">
                  <div class="client-box w-100">
                    <a href="javascript:void(0);" title=""
                      ><img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/client-img1-1.png"
                        alt="Client Image 1"
                    /></a>
                  </div>
                </div>
                <div class="col-md-3 col-sm-4 col-lg-2">
                  <div class="client-box w-100">
                    <a href="javascript:void(0);" title=""
                      ><img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/client-img1-2.png"
                        alt="Client Image 2"
                    /></a>
                  </div>
                </div>
                <div class="col-md-3 col-sm-4 col-lg-2">
                  <div class="client-box w-100">
                    <a href="javascript:void(0);" title=""
                      ><img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/client-img1-3.png"
                        alt="Client Image 3"
                    /></a>
                  </div>
                </div>
                <div class="col-md-3 col-sm-4 col-lg-2">
                  <div class="client-box w-100">
                    <a href="javascript:void(0);" title=""
                      ><img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/client-img1-4.png"
                        alt="Client Image 4"
                    /></a>
                  </div>
                </div>
                <div class="col-md-3 col-sm-4 col-lg-2">
                  <div class="client-box w-100">
                    <a href="javascript:void(0);" title=""
                      ><img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/client-img1-5.png"
                        alt="Client Image 5"
                    /></a>
                  </div>
                </div>
                <div class="col-md-3 col-sm-4 col-lg-2">
                  <div class="client-box w-100">
                    <a href="javascript:void(0);" title=""
                      ><img
                        class="img-fluid"
                        src="../assets/blubuild/images/resources/client-img1-6.png"
                        alt="Client Image 6"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Clients Wrap -->
          </div>
        </div>
      </section>
      <Footer />
      <!-- Footer -->
      <Copyright />
      <!-- Copyright -->
    </main>
    <!-- Main Wrapper -->
  </div>
</template>

<script>
import Header from "../components/layouts/Header";
import StickyMenu from "../components/layouts/StickyMenu";
import ResponsiveHeader from "../components/layouts/ResponsiveHeader";
import Slider from "../components/home/slider/Slider"
import Footer from "../components/layouts/Footer";
import Copyright from "../components/layouts/Copyright";

export default {
  name: "Home",
  components: {
    Header,
    StickyMenu,
    ResponsiveHeader,
    Slider,
    Footer,
    Copyright
  }
};
</script>
